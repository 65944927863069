<!-- cv page -->
<template>
  <div data-app>
    <div class="row">
      <div class="col-md-12">
        <!-- create form start -->
        <v-card v-if="createForm">
          <div style="padding: 2%">
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="row">
                <div class="col-md-3">
                  <v-text-field
                    v-model="cvTitle"
                    :rules="nameRules"
                    label="CV title"
                    required
                  ></v-text-field>
                </div>

                <div class="col-md-3">
                  <v-file-input
                    style="font-size: 8px"
                    label="Choose your CV"
                    v-model="cvFile"
                  >
                  </v-file-input>
                </div>

                <div class="col-md-3">
                  <b-form-select
                    v-model="introVideo"
                    :options="introVideosOptions"
                    size="sm"
                    class="mt-3"
                    required
                  ></b-form-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="introAudio"
                    :options="introAudiosOptions"
                    size="sm"
                    class="mt-3"
                    required
                  ></b-form-select>
                  <br /><br />
                  <label
                    >Note: Please Upload PDF or Doc File. It Should not be
                    larger than 3 MB in size.</label
                  >
                </div>
              </div>
              <div class="row" style="margin-top: 2%">
                <div class="col-md-6">
                  <vue-editor
                    v-model="coverLetter"
                    outlined
                    name="input-7-4"
                    label="Cover letter"
                    rows="6"
                  ></vue-editor>
                </div>
                <div class="col-md-6">
                  <v-textarea
                    v-model="internelNotes"
                    outlined
                    name="input-7-4"
                    label="Internel notes"
                    rows="12"
                  ></v-textarea>
                </div>
              </div>
              <v-btn
                class="
                  btn btn-sm btn-success
                  font-weight-bold
                  py-2
                  px-3 px-xxl-5
                  my-1
                "
                @click="createCV"
                :disabled="
                  cvTitle === '' ||
                  (cvTitle === null && cvFile === '') ||
                  cvFile === null
                    ? true
                    : false
                "
                ><b-spinner small type="grow" v-if="submitting"></b-spinner>
                Save
              </v-btn>
              <v-btn
                style="margin-left: 6%"
                class="
                  btn btn-sm btn-danger
                  font-weight-bold
                  py-2
                  px-3 px-xxl-5
                  my-1
                "
                @click="cancel"
              >
                Cancel
              </v-btn>
            </v-form>
          </div>
        </v-card>
        <!-- create form end -->

        <!-- update form start -->
        <!-- <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="cvTitle"
            :rules="nameRules"
            label="CV title"
            required
          ></v-text-field>

          <v-file-input
            style="font-size: 8px"
            label="Choose your CV"
            v-model="cvFile"
          >
          </v-file-input>

          <b-form-select size="sm" class="mt-3" required></b-form-select
          ><br /><br />
          <label
            >Note: Please Upload PDF or Doc File. It Should not be larger than 3
            MB in size.</label
          >
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="updateCV"
            :disabled="cvTitle === '' || cvTitle === null ? true : false"
            ><b-spinner small type="grow" v-if="submitting"></b-spinner>
            Update
          </v-btn>
          <v-btn
            style="margin-left: 6%"
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-form> -->

        <v-card v-if="updateForm">
          <div style="padding: 2%">
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="row">
                <div class="col-md-3">
                  <v-text-field
                    v-model="cvTitle"
                    :rules="nameRules"
                    label="CV title"
                    required
                  ></v-text-field>
                </div>

                <div class="col-md-3">
                  <v-file-input
                    style="font-size: 8px"
                    label="Choose your CV"
                    v-model="cvFile"
                  >
                  </v-file-input>
                </div>

                <div class="col-md-3">
                  <b-form-select
                    v-model="introVideo"
                    :options="introVideosOptions"
                    size="sm"
                    class="mt-3"
                    required
                  ></b-form-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="introAudio"
                    :options="introAudiosOptions"
                    size="sm"
                    class="mt-3"
                    required
                  ></b-form-select
                  ><br /><br />
                  <label
                    >Note: Please Upload PDF or Doc File. It Should not be
                    larger than 3 MB in size.</label
                  >
                </div>
              </div>
              <div class="row" style="margin-top: 2%">
                <div class="col-md-6">
                  <vue-editor
                    v-model="coverLetter"
                    outlined
                    name="input-7-4"
                    label="Cover letter"
                    rows="6"
                  ></vue-editor>
                </div>
                <div class="col-md-6">
                  <v-textarea
                    v-model="internelNotes"
                    outlined
                    name="input-7-4"
                    label="Internel notes"
                    rows="12"
                  ></v-textarea>
                </div>
              </div>
              <v-btn
                class="
                  btn btn-sm btn-success
                  font-weight-bold
                  py-2
                  px-3 px-xxl-5
                  my-1
                "
                @click="updateCV"
                :disabled="
                  cvTitle === '' ||
                  (cvTitle === null && cvFile === '') ||
                  cvFile === null
                    ? true
                    : false
                "
                ><b-spinner small type="grow" v-if="submitting"></b-spinner>
                Update
              </v-btn>
              <v-btn
                style="margin-left: 6%"
                class="
                  btn btn-sm btn-danger
                  font-weight-bold
                  py-2
                  px-3 px-xxl-5
                  my-1
                "
                @click="cancel"
              >
                Cancel
              </v-btn>
            </v-form>
          </div>
        </v-card>
        <!-- update form end -->
      </div>

      <div class="col-md-12">
        <div class="col-12 row m-0 p-0 bg-white">
          <div class="col-6 py-4 px-4 border_b_silver">
            <span class="card-label font-weight-bolder Main_Blue"
              >Manage your CV</span
            >
          </div>
          <div class="col-6 p-0 pt-3 text-end border_b_silver">
            <button
              type="button"
              class="custom-add-new-record-button btn_dash_ch mr-2"
              fab
              small
              @click="createFormEnable"
            >
              <span class="v-btn__content" style="font-size: 14px !important"
                >Add new CV</span
              >
            </button>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="career_level"
          class="rounded-0 table-borderless px-4 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch mb-4">
              <td
                class="font-weight-bolder Main_Blue"
                style="font-size: 14px; width: 33%"
              >
                {{ props.item.cv_title }}
              </td>
              <td
                class="font-weight-bolder Main_Blue"
                style="font-size: 12px; font-weight: 500 !important; width: 33%"
              >
                {{
                  moment(props.item.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )
                }}
              </td>
              <td style="width: 25%">
                <toggle-button
                  :value="props.item.default == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  class="pl-4"
                  @change="changeStatus(props.item)"
                />
                <!-- {{
                  props.item.default
                    ? "Selected as Default"
                    : "Select as Default"
                }} -->
              </td>
              <td
                class="pt-3 border-0"
                style="display: flex; flex-direction: row"
              >
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    class="dashbord"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="viewButtonClick(props.item.path)"
                >
                  <img
                    src="\images/job_application/View_Icon.svg"
                    alt=""
                    class="dashbord"
                    width="16"
                    height="16"
                  />
                  <!-- <span>{{ props.item.path }}</span> -->
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item)"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    class="dashbord"
                    width="16"
                    height="16"
                  />
                </v-btn>
                <!-- <v-btn
                  class="
                    btn btn-sm btn-success
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn> -->
                <!-- <v-btn
                  class="
                    btn btn-sm btn-danger
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  fab
                  small
                  @click="askUserAboutDelete(props.item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn> -->
              </td>
            </tr>
            <!-- <tr v-bind:key="i">
              <td style="height: 12px" colspan="12"></td>
            </tr> -->
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Do you really want to DELETE this item?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false"
              >No</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import { MEDIA_URL } from "@/core/api";
import Swal from "sweetalert2";
import JobSeekerService from "@/MainServices/JobSeekerService.js";
import { VueEditor } from "vue2-editor";
import moment from "moment";

export default {
  data() {
    return {
      MEDIA_URL: MEDIA_URL,
      loading: true,
      dialog: false,
      deleteId: null,
      deleting: false,
      success: false,
      submitting: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      cvTitle: "",
      cvFile: "",
      cvId: null,
      nameRules: [
        (v) => !!v || "CV title is required",
        (v) =>
          (v && v.length <= 20) || "CV title must be less than 20 characters",
      ],
      headers: [
        { text: "CV Title", value: "cv_title" },
        { text: "Added On", value: "created_at" },
        { text: "SELECT DEFAULT", value: "default" },
        { text: "Action", value: "action" },
      ],
      data: [],
      introVideo: null,
      introVideosOptions: [],
      introAudio: null,
      introAudiosOptions: [],
      internelNotes: "",
      coverLetter: "",
    };
  },
  mounted() {
    this.getInititalData();
  },
  components: {
    VueEditor,
  },
  methods: {
    moment: function () {
      return moment();
    },
    getInititalData() {
      JobSeekerService.getMyCvs()
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });

      JobSeekerService.getMyVideos()
        .then((_res) => {
          this.introVideosOptions = [];
          this.loading = false;
          _res.data.forEach((element) => {
            this.introVideosOptions.push({
              value: element.id,
              text: element.title,
            });
          });
          this.introVideosOptions.unshift(
            ...[{ value: null, text: "Choose intro video" }]
          );
        })
        .catch(() => {
          this.loading = false;
        });
      // this.introAudiosOptions.unshift(
      //     ...[{ value: null, text: "Choose intro Audio" }]
      //   );
      JobSeekerService.getMyAudios()

        .then((_res) => {
          this.introAudiosOptions = [];
          this.loading = false;
          _res.data.forEach((element) => {
            this.introAudiosOptions.push({
              value: element.id,
              text: element.title,
            });
          });
          this.introAudiosOptions.unshift(
            ...[{ value: null, text: "Choose intro Audio" }]
          );
        })
        .catch(() => {
          this.loading = false;
        });
    },

    cancel() {
      this.dialog = false;
      this.createForm = false;
      this.updateForm = false;
      this.cvTitle = "";
      this.cvFile = "";
      this.loading = false;
      this.success = true;
      this.submitting = false;
      this.introVideo = null;
      this.introAudio = null;
      this.internelNotes = "";
      this.coverLetter = "";
    },
    createFormEnable() {
      this.career_level = "";
      this.createForm = true;
      this.updateForm = false;
    },
    createCV() {
      if (this.cvFile == "" || this.cvTitle == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      this.submitting = true;
      let formData = new FormData();
      formData.append("cv_title", this.cvTitle);
      formData.append("cv_file", this.cvFile);
      formData.append("intro_video", this.introVideo);
      formData.append("intro_audio", this.introAudio);
      formData.append("cover_letter", this.coverLetter);
      formData.append("internel_notes", this.internelNotes);
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/job-seeker-get-cvs`, formData)
        .then((res) => {
          this.cancel();
          this.getInititalData();
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        })
        .catch(() => {
          this.loading = false;
          this.submitting = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    viewButtonClick(path) {
      // console.log("path", path);
      // console.log("path", this.MEDIA_URL);
      window.open(this.MEDIA_URL + path);
    },
    updateCV() {
      if (this.cvTitle == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      this.submitting = true;
      let formData = new FormData();
      formData.append("cv_title", this.cvTitle);
      formData.append("intro_video", this.introVideo);
      formData.append("intro_audio", this.introAudio);
      formData.append("cover_letter", this.coverLetter);
      formData.append("internel_notes", this.internelNotes);
      if (this.cvFile !== "") {
        formData.append("cv_file", this.cvFile);
      }

      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/job-seeker-get-cvs/${this.cvId}`, formData)
        .then((res) => {
          this.cancel();
          this.getInititalData();
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        })
        .catch(() => {
          this.loading = false;
          this.submitting = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-seeker-set-cv-default/${item.id}`)
        .then((res) => {
          this.loading = false;
          this.success = true;

          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.cvTitle = item.cv_title;
      this.cvId = item.id;
      this.introVideo = item.intro_video;
      this.introAudio = item.intro_audio;
      this.internelNotes = item.internel_notes;
      this.coverLetter = item.cover_letter;
      console.log(this.cvId);
    },

    deleteButtonClick(item) {
      JobSeekerService.deleteMyCv(item)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },

    deleteItem() {
      this.deleteButtonClick(this.deleteId);
    },
  },
};
</script>

<style >
.container {
  max-width: none !important;
}
</style>